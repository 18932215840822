import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Toaster } from 'react-hot-toast';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter} from 'react-router-dom';
import './i18n';
import './App.css'
import './assets/css/theme.css'
// import './assets/css/theme.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>

    <Toaster 
      containerClassName="toast_message"
    gutter = {100}

    toastOptions = {{
    
      success: {
        duration: 5000,
        iconTheme: {
          primary: '#84d32a',
          secondary: 'white',
        },
        
        style: {
          background: '#4d9100',
          color:'white',
          position:"relative",
          zIndex:1      
        },
      },

      error: {
        duration: 5000,
        zIndex:1,
        iconTheme: {
          primary: 'red',
          secondary: 'white',
        },
        
        style: {
          background: '#c51818',
          color:'rgb(241 241 241)',
          fontSize:'14px',
          position:"relative",
          zIndex:1  
        },
      },
    }}
  />

  <BrowserRouter>
    <App />
  </BrowserRouter>

  </>
);

reportWebVitals();
