import React from 'react'
const Organizationmember = () => {
    return (
        <div className='Election-Manifesto'>

            <section className="inner-banner-Media">
                <div className="container">
                    <div className="inner-bannerimg"></div>
                </div>
            </section>
            <div className='container mt-5'>
                <div className="Translations-image">
                    <nav>
                        <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                            <button
                                class="nav-link active"
                                id="nav-home-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-home"
                                type="button"
                                role="tab"
                                aria-controls="nav-home"
                                aria-selected="true"
                            >
                                தலைமை
                            </button>
                            <button
                                class="nav-link"
                                id="nav-profile-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-profile"
                                type="button"
                                role="tab"
                                aria-controls="nav-profile"
                                aria-selected="false"
                            >
                                செயலாளர்
                            </button>
                            <button
                                class="nav-link"
                                id="nav-foot-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-foot"
                                type="button"
                                role="tab"
                                aria-controls="nav-foot"
                                aria-selected="false"
                            >
                                பொருலாளர்
                            </button>
                        </div>
                    </nav>
                </div>
                <div className="Translations-video">
                    <div class="tab-content p-3 " id="nav-tabContent">
                        <div
                            class="tab-pane fade active show"
                            id="nav-home"
                            role="tabpanel"
                            aria-labelledby="nav-home-tab"
                        >

                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='Central'>

                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/joinmember.png'} className="img-fluid m-auto d-block " alt="image" />

                                        <h2 className='text-center'>விஜய்</h2>
                                        <h6 className='text-center'>தமிழக வெற்றிக்கழகம்</h6>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="nav-profile"
                            role="tabpanel"
                            aria-labelledby="nav-profile-tab"
                        >
                            <div className='row'>

                                <div className='Central'>
                                    <div className='desitali px-3'>
                                        <div className='row '>
                                            <div className='col-lg-6 file'>
                                                <div className='row'>
                                                    <div className='col-lg-4'>
                                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png '} className=" mb-3 member-banner img-fluid " alt="icon" />
                                                    </div>
                                                    <div className='col-lg-8'>
                                                        <h2>மாநிலச் செயலாளர்</h2>
                                                        <h6>திருமதி சி. விஜயலட்சுமி B.Com</h6>
                                                        <p>ஜெ.கே.கே.சுந்தரம் நகர், குமாரபாளையம்,</p>
                                                        <p>நாமக்கல் மாவட்டம்-638183 </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <div className='row mt-4'>
                                                    <div className='col-lg-4'>
                                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png '} className=" mb-3 member-banner img-fluid " alt="icon" />
                                                    </div>
                                                    <div className='col-lg-8'>
                                                        <h2>மாநில இணைச் செயலாளர்</h2>
                                                        <h6>திருமதி எஸ்.என். யாஸ்மின் B.A.B.I</h6>
                                                        <p>78. ஸ்ரீராம் நகர், காந்தி நகர் அஞ்சல், வடக்குத்து, குறிஞ்சிப்பாடி வட்டம், கடலூர் மாவட்டம்-607308</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='line'></div>
                                            <div className='col-lg-6 file'>
                                                <div className='row mt-4'>
                                                    <div className='col-lg-4'>
                                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png '} className=" mb-3 member-banner img-fluid " alt="icon" />
                                                    </div>
                                                    <div className='col-lg-8'>
                                                        <h2>மாநிலத் துணைச் செயலாளர்</h2>
                                                        <h6>திரு. ஏ. விஜய்அன்பன் கல்லணை B.Com</h6>
                                                        <p>2/38, ஓடைப்பட்டி, கருப்பாயூரணி அஞ்சல், மதுரை - 625020.</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='row mt-4'>
                                                    <div className='col-lg-4'>
                                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png '} className=" mb-3 member-banner img-fluid " alt="icon" />
                                                    </div>
                                                    <div className='col-lg-8'>
                                                        <h2>மாநிலத் துணைச் செயலாளர்</h2>
                                                        <h6>திரு. எம்.எல். பிரபு B.A.LIE MES(C.1).</h6>
                                                        <p>R 654, 82ஆவது தெரு, 4ஆவது பிளாக். முத்தமிழ் நகர். கொடுங்கையூர், சென்னை - 600118.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="nav-foot"
                            role="tabpanel"
                            aria-labelledby="nav-foot-tab"
                        >
                            <div className='row'>

                                <div className='Central'>
                                    <div className='desitali px-3'>
                                        <div className='row '>
                                            <div className='col-lg-6 '>
                                                <div className='row'>
                                                    <div className='col-lg-4'>
                                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png '} className=" mb-3 member-banner img-fluid " alt="icon" />
                                                    </div>
                                                    <div className='col-lg-8'>
                                                        <h2>மாநிலப் பொருளாளர்</h2>
                                                        <h6>திரு. வி. சம்பத்குமார் ILA</h6>
                                                        <p>55, பூந்தோட்டம் மெயின், கணேஷ் லே-அவுட் கணபதி, கோயம்புத்தூர்- 641006.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>






            </div>
        </div>
    )
}

export default Organizationmember