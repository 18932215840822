import React, { useState } from 'react'
import './Education.css'
import Carousels from "react-multi-carousel";
import { Carousel } from 'react-bootstrap';


import { useTranslation } from 'react-i18next'

const Education = () => {

    const { t } = useTranslation()

    const [activeIndex, setActiveIndex] = useState(0);


    const responsive1 = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const data = [
        {
            images: [
                process.env.PUBLIC_URL + '/images/Education.png',
                process.env.PUBLIC_URL + '/images/Education.jpg'
            ],
            captions: [
                t('vijayspeech'),
                t('கல்வித்துறைகருத்து'),
            ],
            Education: [
                t('vijaytechspeech'),
                t('கல்வித்துறைஉள்ளடக்கம்')
            ],
            Education1: [
                t('vijaytechspeech1'),
                t('கல்வித்துறைஉள்ளடக்கம்1')
            ],
            Education2: [
                t('vijaytechspeech2'),
                t('கல்வித்துறைஉள்ளடக்கம்2')
            ]
        }
    ];

    console.log(data)
    return (
        <div className='Education'>
            <section className='inner-banner-education'>
                <div className='container'>
                    <div className='inner-bannerimg'>

                    </div>
                </div>
            </section>
            <div className='container'>
                <div className='row'>
                    <div className='Education-video'>
                        <div className='Campaign-videos'>
                            <Carousels
                                swipeable={false}
                                draggable={false}
                                showDots={false}
                                responsive={responsive1}
                                autoPlay={true}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlaySpeed={1500}
                                keyBoardControl={true}
                                className="slider1"
                                slidesToShow={1}
                                slidesToScroll={1}
                                transitionDuration={500}
                                containerclassName="carousel-container"
                                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                                dotListclassName="custom-dot-list-style"
                                itemclassName="carousel-item-padding-40-px"

                            >
                                <div className='campaign-videos'>
                                    <div className="video-container">
                                        {/* <iframe width="1280" height="720" src="https://www.youtube.com/embed/bXZFNn0UmZM" title="zd தளபதி விஜய் நூலக திறப்பு விழா காணொளி | Thalapathy Vijay Library Inauguration Ceremony" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                                        <iframe width="1280" height="720" src="https://www.youtube.com/embed/dquN1kghXms?si=rS1fcu_MXrORX1U2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                    </div>
                                </div>

                                <div className='campaign-videos'>
                                    <div className="video-container">
                                        {/* <iframe width="1280" height="720" src="https://www.youtube.com/embed/fRtpgKrEEjA" title="Actor vijay meet students | மாணவி நந்தினிக்கு வைர நெக்லஸ் வழங்கிய நடிகர் விஜய்! | Sun News" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                                        <iframe width="1280" height="720" src="https://www.youtube.com/embed/Ptu_bW1gwJQ?si=Jf5bEI3hskYUKJrz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                    </div>
                                </div>

                                <div className='campaign-videos'>

                                    <div className="video-container">
                                        {/* <iframe width="1280" height="720" src="https://www.youtube.com/embed/xuhkDQ1_ld4" title="விஜய்க்கு மாலை❤️ அணிவித்த மாணவி ! Thalapathy Vijay Educational Awards" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                                        <iframe width="1280" height="720" src="https://www.youtube.com/embed/qAQzUXeQsW4?si=jjsanlLJDE9eZyno" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                                    </div>

                                </div>
                                <div className='campaign-videos'>
                                    <div className="video-container">
                                        {/* <iframe width="1280" height="720" src="https://www.youtube.com/embed/T2ZK2Zr3XnQ" title="Diamond Necklace ! Vijay Surprise Gift | Thalapathy Vijay Educational Awards" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                                        <iframe width="1280" height="720" src="https://www.youtube.com/embed/XDnnuVHP37c?si=rf2xXAYfQsmM9KCu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                    </div>
                                </div>
                                <div className='campaign-videos'>
                                    <div className="video-container">
                                        {/* <iframe width="1280" height="720" src="https://www.youtube.com/embed/rXx0WmJvR1M" title="Thalapathy Vijay Full Speech - Vijay Makkal Iyakkam | Leo |  Education Award Ceremony 2023" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                                        <iframe width="1280" height="720" src="https://www.youtube.com/embed/Y1h3Y5aIrz8?si=jmCfhSNAJ6JBrvbc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                    </div>
                                </div>




                            </Carousels>
                        </div>
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col-lg-5'>
                        <Carousel activeIndex={activeIndex} onSelect={(index) => setActiveIndex(index)}>
                            {data[0].images.map((imageUrl, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        className='design-Education'
                                        src={imageUrl}
                                        alt={`Education Image ${index}`}
                                        loading="lazy"
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                    <div className='col-lg-7'>
                        <div className='Education-poster'>
                            <h2 className='element'>{t('கல்வித்துறைதலைப்பு')}</h2>
                            <p className='mt-3 function'>{data[0].captions[activeIndex]}</p>

                            <div className='slogan d-flex'>
                                <span><a href="#" class="intro-banner-vdo-play-btn pinkBg" target="_blank">
                                    <i class="glyphicon glyphicon-play whiteText" aria-hidden="true"></i>
                                    <span class="ripple pinkBg"></span>
                                    <span class="ripple pinkBg"></span>
                                    <span class="ripple pinkBg"></span>
                                </a></span> <p>{data[0].Education[activeIndex]}</p>

                            </div>
                            <div className='mani-point slogan d-flex'>
                                <span><a href="#" class="intro-banner-vdo-play-btn pinkBg" target="_blank">
                                    <i class="glyphicon glyphicon-play whiteText" aria-hidden="true"></i>
                                    <span class="ripple pinkBg"></span>
                                    <span class="ripple pinkBg"></span>
                                    <span class="ripple pinkBg"></span>
                                </a></span> <p>{data[0].Education1[activeIndex]} </p>

                            </div>
                            <div className='slogan d-flex'>
                                <span><a href="#" class="intro-banner-vdo-play-btn pinkBg" target="_blank">
                                    <i class="glyphicon glyphicon-play whiteText" aria-hidden="true"></i>
                                    <span class="ripple pinkBg"></span>
                                    <span class="ripple pinkBg"></span>
                                    <span class="ripple pinkBg"></span>
                                </a></span> <p> {data[0].Education2[activeIndex]}</p>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Education