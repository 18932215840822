import React, { useEffect } from "react";
import "./Network.css";
import { Link } from "react-router-dom";
import { FaFacebook, FaYoutube, FaInstagram } from "react-icons/fa";
import { FaXTwitter, FaLinkedin } from "react-icons/fa6";

import { useTranslation } from "react-i18next";

const Network = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="network-strucuture">
      <section className="inner-banner">
        <div className="container">
          <div className="inner-bannerimg"></div>
        </div>
      </section>
      <section className="organi-list my-5">
        <div className="container">
          <div className="social-media">
            <div className="card">
              <h4 className="">{t("சமூகவலைத்தளம்")} </h4>
              <p>{t("சமூகவலைத்தளம்கருத்து")}</p>
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <div className="media-demo">
                    <h2 className="element text-center">{t("ஊடகம்")} :</h2>

                    <div className="face-media socailico d-flex gap-2">
                      <Link
                        to="https://www.instagram.com/tamilaga_vetri_kalagam_offi/"
                        className="social-button facebook"
                      >
                        <i className="facebook">
                          <FaFacebook />
                        </i>
                      </Link>
                      <p>Tamilaga Vetri Kazhagam</p>
                    </div>
                    <div className="face-media socailico d-flex gap-2">
                      <Link
                        to="https://www.facebook.com/p/Tamizhaga-Vetri-Kazhagam-TVK-61555880680847/"
                        className="social-button instagram"
                      >
                        <i className="instagrams">
                          <FaInstagram />
                        </i>
                      </Link>
                      <p>tamilaga_vetri_kalagam_offi</p>
                    </div>
                    <div className="face-media socailico d-flex gap-2">
                      <Link
                        to="https://twitter.com/tvkvijayhq"
                        className="social-button twitter"
                      >
                        <i className="twitter">
                          <FaXTwitter />
                        </i>
                      </Link>
                      <p>@tvkvijayhq</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  {/* <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                                        <div class="carousel-inner">
                                            <div class="carousel-item active">
                                                <img loading="lazy" src={process.env.PUBLIC_URL + '/images/network-page1.jpg'} className="img-fluid m-auto d-block " alt="icon" />
                                            </div>
                                            <div class="carousel-item">
                                                <img loading="lazy" src={process.env.PUBLIC_URL + '/images/network-page2.jpg'} className="img-fluid m-auto d-block " alt="icon" />
                                            </div>
                                            <div class="carousel-item">
                                                <img loading="lazy" src={process.env.PUBLIC_URL + '/images/network-page3.jpg'} className="img-fluid m-auto d-block " alt="icon" />
                                            </div>
                                        </div>
                                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Previous</span>
                                        </button>
                                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div> */}

                  <div className="img_animation">
                    <div className="imgLoader" />
                    <div id="background" className="clip bg_1" />
                    <div id="clip3" className="clip bg_1" />
                    <div id="clip4" className="clip bg_1" />
                    <div id="clipa" className="clip bg_1" />
                    <div id="clip1" className="clip bg_1" />
                    <div id="clip2" className="clip bg_1" />
                    <div id="clipb" className="clip bg_1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Network;
