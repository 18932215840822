import React from "react";
import "./Gallery.css";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next'
const Gallery = () => {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Gallery">
      <section className="inner-banner-Gallery">
        <div className="container">
          <div className="inner-bannerimg"></div>
        </div>
      </section>
      <div className="line-images"></div>

      <div className="container mt-5">
        <div className="row">
          <div className="Translations-image">
            <nav>
              <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                <button
                  class="nav-link active"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  {t('காட்சி')}
                </button>
                <button
                  class="nav-link"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  {t('காணொளி')}
                </button>
              </div>
            </nav>
          </div>
          <div className="Translations-video">
            <div class="tab-content p-3 " id="nav-tabContent">
              <div
                class="tab-pane fade active show"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div className="gallery-collapse">
                  <div className="gallery__column">
                    <a href="#" target="_blank" className="gallery__link">
                      <figure className="gallery__thumb">
                        <img
                          loading="lazy"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/gallery/gallery1.jpeg"
                          }
                          className="img-fluid shortimg"
                          alt="icon"
                        />
                        <figcaption className="gallery__caption">
                          தமிழக வெற்றிக் கழகம்
                        </figcaption>
                      </figure>
                    </a>
                    <a href="#" target="_blank" className="gallery__link">
                      <figure className="gallery__thumb">
                        <img
                          loading="lazy"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/gallery/gallery15.jpg"
                          }
                          className="longimg img-fluid"
                          alt="icon"
                        />
                        <figcaption className="gallery__caption">
                          தமிழக வெற்றிக் கழகம்
                        </figcaption>
                      </figure>
                    </a>
                    <a href="#" target="_blank" className="gallery__link">
                      <figure className="gallery__thumb">
                        <img
                          loading="lazy"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/gallery/gallery10.png"
                          }
                          className="shortimg img-fluid"
                          alt="icon"
                        />
                        <figcaption className="gallery__caption">
                          தமிழக வெற்றிக் கழகம்
                        </figcaption>
                      </figure>
                    </a>
                  </div>
                  <div className="gallery__column">
                    <a href="#" target="_blank" className="gallery__link">
                      <figure className="gallery__thumb">
                        <img
                          loading="lazy"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/gallery/gallery11.jpg"
                          }
                          className="shortimg img-fluid"
                          alt="icon"
                        />
                        <figcaption className="gallery__caption">
                          தமிழக வெற்றிக் கழகம்
                        </figcaption>
                      </figure>
                    </a>
                    <a href="#" target="_blank" className="gallery__link">
                      <figure className="gallery__thumb">
                        <img
                          loading="lazy"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/gallery/gallery18.jpg"
                          }
                          className="shortimg img-fluid"
                          alt="icon"
                        />
                        <figcaption className="gallery__caption">
                          தமிழக வெற்றிக் கழகம்
                        </figcaption>
                      </figure>
                    </a>
                    <a href="#" target="_blank" className="gallery__link">
                      <figure className="gallery__thumb">
                        <img
                          loading="lazy"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/gallery/gallery16.jpg"
                          }
                          className="longimg img-fluid"
                          alt="icon"
                        />
                        <figcaption className="gallery__caption">
                          தமிழக வெற்றிக் கழகம்
                        </figcaption>
                      </figure>
                    </a>
                  </div>
                  <div className="gallery__column">
                    <a href="#" target="_blank" className="gallery__link">
                      <figure className="gallery__thumb">
                        <img
                          loading="lazy"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/gallery/gallery8.png"
                          }
                          className="longimg img-fluid"
                          alt="icon"
                        />
                        <figcaption className="gallery__caption">
                          தமிழக வெற்றிக் கழகம்
                        </figcaption>
                      </figure>
                    </a>
                    <a href="#" target="_blank" className="gallery__link">
                      <figure className="gallery__thumb">
                        <img
                          loading="lazy"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/gallery/gallery9.png"
                          }
                          className="shortimg img-fluid"
                          alt="icon"
                        />
                        <figcaption className="gallery__caption">
                          தமிழக வெற்றிக் கழகம்
                        </figcaption>
                      </figure>
                    </a>
                    <a href="#" target="_blank" className="gallery__link">
                      <figure className="gallery__thumb">
                        <img
                          loading="lazy"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/gallery/gallery14.jpg"
                          }
                          className="shortimg img-fluid"
                          alt="icon"
                        />
                        <figcaption className="gallery__caption">
                          தமிழக வெற்றிக் கழகம்
                        </figcaption>
                      </figure>
                    </a>
                  </div>
                  <div className="gallery__column">
                    <a href="#" target="_blank" className="gallery__link">
                      <figure className="gallery__thumb">
                        <img
                          loading="lazy"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/gallery/gallery3.jpg"
                          }
                          className="shortimg img-fluid"
                          alt="icon"
                        />
                        <figcaption className="gallery__caption">
                          தமிழக வெற்றிக் கழகம்
                        </figcaption>
                      </figure>
                    </a>
                    <a href="#" target="_blank" className="gallery__link">
                      <figure className="gallery__thumb">
                        <img
                          loading="lazy"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/gallery/gallery2.jpg"
                          }
                          className="shortimg img-fluid"
                          alt="icon"
                        />
                        <figcaption className="gallery__caption">
                          தமிழக வெற்றிக் கழகம்
                        </figcaption>
                      </figure>
                    </a>
                    <a href="#" target="_blank" className="gallery__link">
                      <figure className="gallery__thumb">
                        <img
                          loading="lazy"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/gallery/gallery12.jpg"
                          }
                          className="longimg img-fluid"
                          alt="icon"
                        />
                        <figcaption className="gallery__caption">
                          தமிழக வெற்றிக் கழகம்
                        </figcaption>
                      </figure>
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <div className="row">
                  <div className="col-lg-4">
                    <div className="campaign-videos">
                      <div className="video-container">
                        <iframe
                          width="1280"
                          height="720"
                          src="https://www.youtube.com/embed/ASn_Me5i5-E"
                          title="த.வெ.க -வில் சேருவது எப்படி ? - தனது ஸ்டைலில் விளக்கிய தலைவர் விஜய் | Vijay | TVK Member App"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerpolicy="strict-origin-when-cross-origin"
                          allowfullscreen
                        ></iframe>
                        {/* <iframe
                          src="https://youtu.be/ugvpKqyDVno"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                        ></iframe> */}
                      </div>
                    </div>{" "}
                  </div>
                  <div className="col-lg-4">
                    <div className="campaign-videos">
                      <div className="video-container">
                        {/* <iframe
                          src="https://www.youtube.com/embed/wn38lMxP_L8?si=-AA8e672evYhRgv_"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                        ></iframe> */}
                        <iframe
                          width="1280"
                          height="720"
                          src="https://www.youtube.com/embed/8Y96kgpI3zA"
                          title="234 தொகுதிகளிலும் மதிய உணவு - விஜய் மக்கள் இயக்கம் அறிவிப்பு! | Vijay Makkal Iyakkam"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerpolicy="strict-origin-when-cross-origin"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="col-lg-4">
                    <div className="campaign-videos">
                      <div className="video-container">
                        {/* <iframe
                          src="https://www.youtube.com/embed/FEHMvNajg5M?si=u3Rtv5rO6PJh5PUr"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                        ></iframe> */}
                        <iframe
                          width="1003"
                          height="752"
                          src="https://www.youtube.com/embed/pNk3rvZwbz0"
                          title="Ilayathalapathy Vijay&#39;s Social welfare activities"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerpolicy="strict-origin-when-cross-origin"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-lg-4">
                    <div className="campaign-videos">
                      <div className="video-container">
                        {/* <iframe
                          src="https://www.youtube.com/embed/FEHMvNajg5M?si=u3Rtv5rO6PJh5PUr"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                        ></iframe> */}
                        <iframe
                          width="1280"
                          height="720"
                          src="https://www.youtube.com/embed/qvxdb254qW8"
                          title="தூத்துக்குடி | நெல்லை மக்களை நேரில் சந்தித்து நிவாரண உதவிகளை வழங்கிய தளபதி விஜய் | Part 1"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerpolicy="strict-origin-when-cross-origin"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="col-lg-4">
                    <div className="campaign-videos">
                      <div className="video-container">
                        <iframe
                          src="https://www.youtube.com/embed/wn38lMxP_L8?si=-AA8e672evYhRgv_"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="col-lg-4">
                    <div className="campaign-videos">
                      <div className="video-container">
                        <iframe
                          width="1280"
                          height="720"
                          src="https://www.youtube.com/embed/uU1Pr8chfco"
                          title="வள்ளலாக தளபதி.. வாழ்த்திய நெல்லை மக்கள்!🙏😍 நலத்திட்ட உதவிகளை வழங்கிய Thalapathy Vijay"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerpolicy="strict-origin-when-cross-origin"
                          allowfullscreen
                        ></iframe>
                        {/* <iframe
                          src="https://www.youtube.com/embed/FEHMvNajg5M?si=u3Rtv5rO6PJh5PUr"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                        ></iframe> */}
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="pagination-gallery mb-5">
          <ul id="border-pagination">
            <li>
              <a className href="#">
                «
              </a>
            </li>
            <li>
              <a href="#">1</a>
            </li>
            <li>
              <a href="#" className="active">
                2
              </a>
            </li>
            <li>
              <a href="#">3</a>
            </li>
            <li>
              <a href="#">4</a>
            </li>
            <li>
              <a href="#">5</a>
            </li>
            <li>
              <a href="#">6</a>
            </li>
            <li>
              <a href="#">7</a>
            </li>
            <li>
              <a href="#">»</a>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default Gallery;
