import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from './locales/en/translation.json';
import translationTam from './locales/tam/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  tam : {
    translation: translationTam
  }
};


i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'tam',

  interpolation: {
    escapeValue: false
  }
});

export default i18n;