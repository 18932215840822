import React, { useEffect, useState } from "react";
import "./Manifesto.css";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import {announceList} from '../../api/apiFun'
import './pagination.css';
const Manifesto = () => {


  const { t } = useTranslation();
  const [announceData ,setAnnounceData] = useState([])
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);



  const viewAnnounceList = (page, per_page, filter = '') =>{
    try{
      let data = { page, per_page, filtered: filter }
      announceList(data).then((result,error)=>{
        if(result && result.status == true){
          setAnnounceData(result.data)
          setTotalRows(result.count)
        }else{
          
        }
      }).catch(err=>{
      console.log("error",err);

      })
    }catch(err){
      console.log("error",err);
    }

  }


  const handlePageChange = (page) => {
    const selectedPage = page.selected + 1;
    viewAnnounceList(selectedPage, perPage);
  };




  useEffect(() => {
    viewAnnounceList(1, perPage)
    window.scrollTo(0, 0);
  }, [perPage]);

  return (
    <div className="Manifesto mb-5">
      <section className="inner-banner-Manifesto">
        <div className="container">
          <div className="inner-bannerimg"></div>
        </div>
      </section>
      <div className="mt-5">
        <div className="line-imagess"></div>
        <div className="Translations-image">
          <nav>
            <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist">
              <button
                class="nav-link active"
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                {t('அறிக்கைகள்')}
              </button>
              <button
                class="nav-link"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                {t('வாழ்த்துகள்')}
              </button>
            </div>
          </nav>
        </div>
      </div>
      <div class="tab-content p-3 " id="nav-tabContent">
        <div
          class="tab-pane fade active show"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <div className="manifest-images">
            <div className="container">
              <div className="row mt-3">
              {announceData.map((announce) => (
                <ManifestCard key={announce?.announceId} imageUrl={announce?.file} />
              ))}
               
               {/* <ManifestCard imageUrl={"/images/manifesto/tvkimg20.jpg"} /> 
                <ManifestCard imageUrl={"/images/manifesto/tvkimg17.jpg"} />
                <ManifestCard imageUrl={"/images/manifesto/tvkimg10.jpg"} />
                <ManifestCard imageUrl={"/images/manifesto/tvkimg12.jpg"} />
                <ManifestCard imageUrl={"/images/manifesto/tvkimg13.jpg"} />
                <ManifestCard imageUrl={"/images/manifesto/tvkimg5.jpg"} />
                <ManifestCard imageUrl={"/images/manifesto/tvkimg6.jpg"} />
                <ManifestCard imageUrl={"/images/manifesto/tvkimg7.jpg"} />
                <ManifestCard imageUrl={"/images/manifesto/tvkimg8.jpg"} />
                <ManifestCard imageUrl={"/images/manifesto/tvkimg9.jpg"} />
                <ManifestCard imageUrl={"/images/manifesto/tvkimg1.jpg"} />
                <ManifestCard imageUrl={"/images/manifesto/tvkimg2.jpg"} />
                <ManifestCard imageUrl={"/images/manifesto/tvkimg3.jpg"} />
                <ManifestCard imageUrl={"/images/manifesto/tvkimg4.jpg"} /> */}


              </div>

            </div>


          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <div className="manifest-images">
            <div className="container">
              <div className="row mt-3">
                <ManifestCard imageUrl={"/images/manifesto/tvkimg16.jpeg"} />
                <ManifestCard imageUrl={"/images/manifesto/tvkimg15.jpg"} />
                <ManifestCard imageUrl={"/images/manifesto/tvkimg19.jpg"} />
                <ManifestCard imageUrl={"/images/manifesto/tvkimg14.jpg"} />
              </div>

            </div>
           
          </div>
        </div>
      </div>
      <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(totalRows / perPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />

    </div>
    
  );
};

export default Manifesto;

const ManifestCard = ({ imageUrl }) => {
  const [isModal, setIsModal] = useState(false);

  const handleModal = (type) => {
    if (type === 1) {
      setIsModal(true);
    }
    if (type === 2) {
      setIsModal(false);
    }
  };

  return (
    <>
      <div className="col-lg-4 col-sm-12 col-md-6 mb-4" onClick={() => handleModal(1)}>
        <article
          className="card"

        >
          <img loading="lazy" src={imageUrl} alt={`image-${imageUrl}`} />
          <span className="top" />
          <span className="right" />
          <span className="bottom" />
          <span className="left" />
        </article>
      </div>
      <Modal show={isModal} onHide={() => handleModal(2)} centered={true}>
        <Modal.Header closeButton={true}></Modal.Header>
        <Modal.Body>
          <img loading="lazy" src={imageUrl} alt="image" className="w-100" />
        </Modal.Body>
      </Modal>
    </>
  );
};
