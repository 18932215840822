import React from "react";
import "./commonevents.css";
import { FaRegCalendar } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { useTranslation } from 'react-i18next'
const Commonevents = () => {
  const { t } = useTranslation()
  return (
    <div className="common-events mb-5">
      <section className="inner-banner-common">
        <div className="container">
          <div className="inner-bannerimg"></div>
        </div>
      </section>
      <div className="line-null mb-5">
        <div className="line mt-5"></div>
        <div className="top-line">{t('பொது நிகழ்வுகள்')}</div>
      </div>
      <div className="events-section">
        <div className="container">
          <div className="row">
            <div className="col-1"></div>
            <div className="col-lg-4  px-0">
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/events/event5.jpg"}
                alt="image"
                className="img-fluid w-100"
              />
            </div>
            <div className="col-lg-1 col-md-2 px-0 m-auto">
              <div className="event-date">
                <h1>26</h1>
                <h6>{t('ஜூன்')} </h6>
              </div>
            </div>
            <div className="col-lg-5 col-md-10 m-auto px-0">
              <div className="event-fun">
                <h2 className="px-3">{t('உறுப்பினர் சேர்க்கை அணி')}</h2>
                <div className="line"></div>
                <p className="px-3 mb-2"> {t('உறுப்பினர் சேர்க்கை அணி-நிர்வாகிகள் நியமன அறிவிப்பின் ஆலோசனை கூட்டம்')}</p>
                <div className="d-flex eventsdate px-3">
                  <span>
                    <i className="calender mx-2">
                      <FaRegCalendar />
                    </i>
                    {t('ஜூன்')} 26 ,2009
                  </span>
                  <span>
                    <i className="polticuser mx-2">
                      <IoLocationOutline />
                    </i>
                    {t('புதுக்கோட்டை')}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
          <br></br>
          <div className="row">

            <div className="col-1"></div>
            <div className="col-lg-5 col-md-8 m-auto px-0">
              <div className="event-fun">
                <h2 className="px-3">{t('பொதுத்தேர்வில் சிறந்த மதிப்பெண்கள்')}</h2>
                < div className="line" ></div>
                <p className="px-3 mb-2"> {t('பொதுத்தேர்வில் சிறந்த மதிப்பெண்கள் பெற்ற மாணவர்கள் மாணவிகளை கௌரவிக்கும் விருது வழங்கும் விழா')}</p>
                < div className="d-flex eventsdate px-3" >
                  <span>
                    <i className="calender mx-2">
                      <FaRegCalendar />
                    </i>
                    {t('ஜூன்')} 06,2023

                  </span>
                  <span>
                    <i className="polticuser mx-2">
                      <IoLocationOutline />
                    </i>
                    {t('புதுக்கோட்டை')}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-md-2 px-0 m-auto">
              <div className="event-date">
                <h1> 06</h1>
                <h6>{t('ஜூன்')}</h6>
              </div>
            </div>
            <div className="col-lg-4 px-0">
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/events/events14.jpg"}
                alt="image"
                className="img-fluid w-100"
              />
            </div>
            <div className="col-1"></div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-1"></div>

            <div className="col-lg-4 px-0">
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/events/events10.jpg"}
                alt="image"
                className="img-fluid w-100"
              />
            </div>
            <div className="col-lg-1 col-md-2 px-0 m-auto">
              <div className="event-date">
                <h1>30</h1>
                <h6>{t('டிசம்பர்')} </h6>
              </div>
            </div>
            <div className="col-lg-5 col-md-10 m-auto px-0">
              <div className="event-fun">
                <h2 className="px-3">{t('வெள்ளத்தால் பாதிக்கப்பட்ட குடும்பங்களுக்கு')} </h2>
                <div className="line"></div>
                <p className="px-3 mb-2">{t('வெள்ளத்தால் பாதிக்கப்பட்ட குடும்பங்களுக்கு நிவாரணப் பொருட்களை வழங்கியபோது')}</p>
                <div className="d-flex eventsdate px-3">
                  <span>
                    <i className="calender mx-2">
                      <FaRegCalendar />
                    </i>
                    {t('டிசம்பர்')} 30,2023

                  </span>
                  <span>
                    <i className="polticuser mx-2">
                      <IoLocationOutline />
                    </i>
                    {t('நெல்லை')}, {t('தூத்துக்குடி')}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
          <br></br>
          <div className="row mt-4">
            <div className="col-1"></div>
            <div className="col-lg-5 col-md-8 m-auto px-0">
              <div className="event-fun">
                <h2 className="px-3">{t('ஊழலுக்கு எதிரான போராட்டத்தில்')}</h2>
                <div className="line"></div>
                <p className="px-3 mb-2"> {t('ஊழலுக்கு எதிரான போராட்டத்தில் அன்னா ஹசாரேவுடன் நடிகர் விஜய் உரையாற்றினார்')}</p>
                <div className="d-flex eventsdate px-3">
                  <span>
                    <i className="calender mx-2">
                      <FaRegCalendar />
                    </i>
                    {t('நவம்பர்')} 17, 2021


                  </span>
                  <span>
                    <i className="polticuser mx-2">
                      <IoLocationOutline />
                    </i>
                    {t('டெல்லி')}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-md-2 px-0 m-auto">
              <div className="event-date">
                <h1> 17</h1>
                <h6>{t('நவம்பர்')}</h6>
              </div>
            </div>
            <div className="col-lg-4 px-0">
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/events/events18.jpg"}
                alt="image"
                className="img-fluid w-100"
              />
            </div>
            <div className="col-1"></div>
          </div>
          <br></br>
          <div className="row">
            <div className="col-1"></div>
            <div className="col-lg-4  px-0">
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/events/event22.jpg"}
                alt="image"
                className="img-fluid w-100"
              />
            </div>
            <div className="col-lg-1 col-md-2 px-0 m-auto">
              <div className="event-date">
                <h1>28</h1>
                <h6>{t('ஜூன்')} </h6>
              </div>
            </div>
            <div className="col-lg-5 col-md-10 m-auto px-0">
              <div className="event-fun">
                <h2 className="px-3">{t('சென்னை கிழக்கு')}</h2>
                <div className="line"></div>
                <p className="px-3 mb-2"> {t('சென்னை கிழக்கு செய்தி')}</p>
                <div className="d-flex eventsdate px-3">
                  <span>
                    <i className="calender mx-2">
                      <FaRegCalendar />
                    </i>
                    {t('ஜூன்')} 28, 2023
                  </span>
                  <span>
                    <i className="polticuser mx-2">
                      <IoLocationOutline />
                    </i>
                    {t('சென்னை')}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
          <div className="row mt-4">
            <div className="col-1"></div>
            <div className="col-lg-5 col-md-8 m-auto px-0">
              <div className="event-fun">
                <h2 className="px-3">{t('சென்னைபுறநகர்')}</h2>
                <div className="line"></div>
                <p className="px-3 mb-2"> {t('சென்னைபுறநகர் செய்தி')}</p>
                <div className="d-flex eventsdate px-3">
                  <span>
                    <i className="calender mx-2">
                      <FaRegCalendar />
                    </i>
                    {t('ஜூன்')} 23, 2023


                  </span>
                  <span>
                    <i className="polticuser mx-2">
                      <IoLocationOutline />
                    </i>
                    {t('சென்னை')}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-md-2 px-0 m-auto">
              <div className="event-date">
                <h1> 23</h1>
                <h6>{t('ஜூன்')}</h6>
              </div>
            </div>
            <div className="col-lg-4 px-0">
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/events/event23.jpg"}
                alt="image"
                className="img-fluid w-100"
              />
            </div>
            <div className="col-1"></div>
          </div>
          <div className="row">
            <div className="col-1"></div>
            <div className="col-lg-4  px-0">
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/events/event18.jpg"}
                alt="image"
                className="img-fluid w-100"
              />
            </div>
            <div className="col-lg-1 col-md-2 px-0 m-auto">
              <div className="event-date">
                <h1>18</h1>
                <h6>{t('மார்ச்')} </h6>
              </div>
            </div>
            <div className="col-lg-5 col-md-10 m-auto px-0">
              <div className="event-fun">
                <h2 className="px-3">{t('நாமக்கல் கிழக்கு')}</h2>
                <div className="line"></div>
                <p className="px-3 mb-2"> {t('நாமக்கல் கிழக்கு செய்தி')}</p>
                <div className="d-flex eventsdate px-3">
                  <span>
                    <i className="calender mx-2">
                      <FaRegCalendar />
                    </i>
                    {t('மார்ச்')} 18 ,2023
                  </span>
                  <span>
                    <i className="polticuser mx-2">
                      <IoLocationOutline />
                    </i>
                    {t('நாமக்கல்')}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>
          <div className="row mt-4">
            <div className="col-1"></div>
            <div className="col-lg-5 col-md-8 m-auto px-0">
              <div className="event-fun">
                <h2 className="px-3">{t('விழுப்புரம் பெண்களுக்கு தையல் இயந்திரம்')}</h2>
                <div className="line"></div>
                <p className="px-3 mb-2"> {t('விழுப்புரம் பெண்களுக்கு தையல் இயந்திரம் செய்தி')}</p>
                <div className="d-flex eventsdate px-3">
                  <span>
                    <i className="calender mx-2">
                      <FaRegCalendar />
                    </i>
                    {t('ஜூன்')} 28, 2023


                  </span>
                  <span>
                    <i className="polticuser mx-2">
                      <IoLocationOutline />
                    </i>
                    {t('விழுப்புரம்')}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-md-2 px-0 m-auto">
              <div className="event-date">
                <h1> 28</h1>
                <h6>{t('ஜூன்')}</h6>
              </div>
            </div>
            <div className="col-lg-4 px-0">
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/events/event25.jpg"}
                alt="image"
                className="img-fluid w-100"
              />
            </div>
            <div className="col-1"></div>
          </div>
        </div>
      </div >
    </div >
  );
};

export default Commonevents;
