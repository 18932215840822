import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { FaFacebook, FaYoutube, FaInstagram } from "react-icons/fa";
import { FaXTwitter, FaLinkedin } from "react-icons/fa6";
import { BiSolidPhoneCall } from "react-icons/bi";

import { useTranslation } from 'react-i18next'

const Footer = () => {

  const { t } = useTranslation()

  return (
    <div className="footer-sec">
      <footer className="text-center text-lg-start">
        <section className="">
          <div className="container text-center text-md-start">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4 mt-3">
                <div className="footer-page">
                  <h4>{t('தமிழக வெற்றிக் கழகம்')}</h4>
                  <p className="thirukural">
                    பிறப்பொக்கும் எல்லா உயிர்க்கும் சிறப்பொவ்வா <br></br>செய்தொழில்
                    வேற்றுமை யான்.<span className="ms-2">(குறள் 972)</span>
                  </p>
                </div>
                <div className="socailico d-flex gap-2">
                  <Link
                    to="https://www.facebook.com/p/Tamizhaga-Vetri-Kazhagam-TVK-61555880680847/"
                    className="social-button facebook"
                  >
                    <i className="facebook">
                      <FaFacebook />
                    </i>
                  </Link>
                  <Link
                    to="https://twitter.com/tvkvijayhq"
                    className="social-button twitter"
                  >
                    <i className="twitter">
                      <FaXTwitter />
                    </i>
                  </Link>
                  {/* <Link to="#" className="social-button linkedin" ><i className="linkedin" ><FaLinkedin /></i></Link> */}
                  {/* <Link to="#" className="social-button youtube"><i className="youtube" ><FaYoutube /></i></Link> */}
                  <Link
                    to="https://www.instagram.com/tamilaga_vetri_kalagam_offi/"
                    className="social-button instagram"
                  >
                    <i className="instagrams">
                      <FaInstagram />
                    </i>
                  </Link>
                </div>
              </div>

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 mt-3">
                <h6 className="text-uppercase fw-bold mb-3">{t('சமூகம்')}</h6>
                <p>
                  <Link to="/Gallery" className="text-reset">
                  {t('காட்சியகம்')} 
                  </Link>
                </p>
                <p>
                  <Link to="/Media" className="text-reset">
                    {t('ஊடகம்')}
                  </Link>
                </p>
                <p>
                  <Link to="/network" className="text-reset">
                  {t('வலைத்தளம்')}
                  </Link>
                </p>
                
              </div>

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 mt-3">
                <h6 className="text-uppercase fw-bold mb-3">{t('கழகம்')} </h6>

                <p>
                  <Link to="/Organization" className="text-reset">
                  {t('கழக அமைப்பு')}
                  </Link>
                </p>
                <p>
                  <Link to="/Administrators" className="text-reset">
                  {t('நிர்வாகிகள்')}
                  </Link>
                </p>
                
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 mt-3">
                <h6 className="text-uppercase fw-bold mb-3">{t('முகவரி')}</h6>
                <p>{t('முகவரி1')}</p>
                <p className="number-footer">
                  {" "}
                  <span className="px-2">
                    <BiSolidPhoneCall />
                  </span>
                  + (91)427-47896589
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="text-center last-footer p-3">
          © 2024 Copyright: {t('தமிழக வெற்றிக் கழகம்')}
        </div>
      </footer>
    </div>
  );
};

export default Footer;
