import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Common from './Helper/Common';
import Auth from './Auth';

import Home from './Component/Home/Home';
import Otp from './Component/Otp/Otp';
import Principle from './Component/Principle/Principle';
import Network from './Component/Network/Network';
import Pledge from "./Component/Pledge/Pledge";
import Achievements from './Component/Achievements/Achievements';
import Events from './Component/Events/Events';
import Media from './Component/Media/Media';
import Manifesto from './Component/Manifesto/Manifesto'
import Gallery from "./Component/Gallery/Gallery";
import Sidebarmenu from './Component/Sidebar/Sidebarmenu'
import Organization from './Component/Organization/Organization';
import Education from './Component/Education/Education';
import Organizationmember from './Component/Organizationmember/Organizationmember';
import  Administrators from './Component/Administrators/Administrators'
import  Poster from './Component/Poster/Poster'
import Commonevents from './Component/Comomonevents/Commonevents';
import History from './Component/History/History';
const Addprofile = React.lazy(() => import('./Component/Addimage/Addprofile'))
const Membercard = React.lazy(() => import('./Component/Membercard/Membercard'))
const Joinmember = React.lazy(() => import('./Component/Joinmember/Joinmember'))
const Userdashboard = React.lazy(() => import('./Component/Userdashboard/Userdashboard'))

function MainIndex() {
    const location = useLocation();
    // const [playedBefore, setPlayedBefore] = useState(false);
    // const [play, { stop }] = useSound('https://res.cloudinary.com/ddgcnuz4k/video/upload/v1713250209/vaa-mudhalvaa-time-to-rule-thalaivar-vijay-tvk-anthem-tamilaga-vettri-k_mgpxSt0F_nzhmxi.mp3', { interrupt: true });

    // useEffect(() => {

    //     if (location.pathname === '/history' && !playedBefore) {
    //         play();
    //         setPlayedBefore(true); 
    //     } else if (location.pathname !== '/history') {
    //         stop();
    //     }
    // }, [location.pathname, playedBefore, play, stop]);

    return (
        <Suspense fallback={'LOADER'}>
            <div>
                <Routes>
                    <Route path="/">
                        <Route path='*' exact={true} element={<Navigate to="/" />} />
                        <Route path="/" element={<Home />} />
                        <Route path="/principle" element={<Principle />} />
                        <Route path="/network" element={<Network />} />
                        <Route path="/pledge" element={<Pledge />} />
                        <Route path="/Achievements" element={<Achievements />} />
                        <Route path="/Events" element={<Events />} />
                        <Route path="/Media" element={<Media />} />
                        <Route path="/announcement" element={<Manifesto />} />
                        <Route path="/Gallery" element={<Gallery />} />
                        <Route path="/Education" element={<Education />} />
                        <Route path="/Organization" element={<Organization />} />
                        <Route path="/Organizationmember" element={<Organizationmember />} />
                        <Route path="/Administrators" element={<Administrators />} />
                        <Route path="/Poster" element={<Poster />} />
                        <Route path="/commonevents" element={<Commonevents />} />
                        <Route path="/history" element={<History />} />


                    </Route>
                    <Route element={<Auth />}>
                        <Route path="/addpost" element={<Addprofile />} />
                        <Route path="/Joinmember" element={<Joinmember />} />
                        <Route path="/membercard" element={<Membercard />} />
                        <Route path="/userdashboard" element={<Userdashboard />} />
                    </Route>
                </Routes>
            </div>
        </Suspense>
    );
}

export default MainIndex;
