import React, { useEffect } from 'react'
import './principle.css'
import { IoBagOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
const Principle = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='tvk-principles'>
            <section className='inner-banner'>
                <div className='container'>
                    <div className='inner-bannerimg'>

                    </div>
                </div>
            </section>
            <div className='line-null mb-5'>
                <div className="line mt-5"></div>
                <div className="top-line">
                கொள்கைகள்   </div>
            </div>
            <section className='pirinciple-qutoes my-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <h6>எங்கள் கொள்கைகள்</h6>
                            <h2 className=' element-left-align mb-3'>கழகத்தின் குறிக்கோள்</h2>
                            <p>“என்னுடைய தாய் தந்தைக்கு அடுத்து எனக்கு பெயர், புகழ் மற்றும் எல்லாமும் கொடுத்த தமிழ்நாட்டு மக்களுக்கும் தமிழ்ச் சமுதாயத்திற்கும் என்னால் முடிந்த வரையில் இன்னும் முழுமையாக உதவ வேண்டும் என்பதே எனது நீண்டகால எண்ணம் மற்றும் விருப்பமாகும்.</p>
                            <p>அரசியல் நிர்ணயச் சட்டத்தில் முழு நம்பிக்கையும், பற்றுறுதியும் கொண்டு சமதர்மம், சமயச்சார்பின்மை, ஜனநாயகம் ஆகிய லட்சியங்களில் முழு ஈடுபாடும், பற்றும் கொண்டு இந்திய நாட்டின் இறையாண்மை, ஒற்றுமை, ஒருமைப்பாடு ஆகியவற்றைக் கட்டிக் காப்பது என்பது <strong>தமிழக வெற்றிக்  கழகத்தின்</strong>   குறிக்கோள் ஆகும்.</p>
                        </div>
                        <div className='col-lg-6'>
                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/img12.png'} className="img-fluid mb-2" alt="icon" />
                        </div>
                    </div>
                    <div className='principle-list mt-5'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className='priciple-one d-flex gap-3'>
                                    <div className='legal'>
                                        <i className='bag'><IoBagOutline /></i>
                                    </div>
                                    <div className='prinicple-content'>
                                        <h5 className='mb-3'>சிறந்த சட்டப் பாதுகாப்பு</h5>
                                        <p>“என்னுடைய தாய் தந்தைக்கு அடுத்து எனக்கு பெயர், புகழ் மற்றும் எல்லாமும் கொடுத்த தமிழ்நாட்டு  </p>
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='priciple-one d-flex gap-3'>
                                    <div className='legal'>
                                        <i className='bag'><IoBagOutline /></i>
                                    </div>
                                    <div className='prinicple-content'>
                                        <h5 className='mb-3'>
                                            திறமையான வழக்கறிஞர்கள்</h5>
                                        <p>“என்னுடைய தாய் தந்தைக்கு அடுத்து எனக்கு பெயர், புகழ் மற்றும் எல்லாமும் கொடுத்த தமிழ்நாட்டு  </p>
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='priciple-one d-flex gap-3'>
                                    <div className='legal'>
                                        <i className='bag'><IoBagOutline /></i>
                                    </div>
                                    <div className='prinicple-content'>
                                        <h5 className='mb-3'>சிறப்பிற்காக பாடுபடுவோம் </h5>
                                        <p>“என்னுடைய தாய் தந்தைக்கு அடுத்து எனக்கு பெயர், புகழ் மற்றும் எல்லாமும் கொடுத்த தமிழ்நாட்டு  </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className='corporation-theroy mb-5'>
                <div className='container'>
                    <h2 className='mb-3  element-left-align'>கழகத்தின் கோட்பாடு</h2>
                    <p>பேரறிஞர் அண்ணா அறிவுறுத்திய கடமை-கண்ணியம்-கட்டுப்பாடு ஆகியவற்றின் அடிப்படையில், அரசியலில் ஜனநாயக உரிமைகளைப் பாதுகாத்து, ஆதிக்கமற்ற சமுதாயத்தை அமைத்திடவும், பகுத்தறிவு அடிப்படையில் மறுமலர்ச்சிக்கான சீர்திருத்தப் பணி ஆற்றிடவும்; பொருளாதாரத் துறையில் வறுமையை வென்று, சமதர்ம அடிப்படையில் எல்லோரும் நல்வாழ்வு பெற வழிவகை கண்டிடவும்; பிறமொழி ஆதிக்கத்திற்கு இடங்கொடாமல் அந்தந்த மாநில மொழிகளை வளர்த்திடவும் அவற்றுக்கான உரிய இடத்தைப் பெற்றுத் தரவும்; மத்திய அரசில் குவிந்து கிடக்கும் அதிகாரங்கள் பரவலாக்கப்பட்டு, மாநிலங்களில் சுயாட்சியும் – ஒன்றிய கூட்டாட்சியும் உருவாகிடவும் தொண்டாற்றுவது தான் <strong>தமிழக வெற்றிக் கழகத்தின்</strong> கோட்பாடு.</p>
                </div>
            </section>
            <section className='joiningmember mb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='card'>
                                <h2 className='mb-3 element-left-align'>இணையுங்கள் </h2>
                                <p>வளமான தமிழ்நாட்டை உருவாக்கும் உறுப்பினராக சேர பதிவு செய்யவும். அனைவரும் இணைந்து சமூக மாற்றத்தை ஏற்படுத்துவோம்.</p>
                                <Link to="/joinmember" className=" joinbtn" >உறுப்பினராக இணைய </Link>
                            </div>

                        </div>

                    </div >
                </div >
            </section >
        </div>
    )
}

export default Principle