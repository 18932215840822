import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'
import "./Media.css";
import {MediaList} from '../../api/apiFun'
import {dateConverter} from '../../Helper/dateConverter'
const Media = () => {
  const { t } = useTranslation()

  const [mediaData, setMediaData] = useState([{}])

  const MediaData = () =>{
    try {

      MediaList().then((result,err)=>{
        if(result && result.status == true){
          setMediaData(result?.data)

        }else{
          console.log("err",err);
        }
      })
    } catch (error) {
        console.log("error",error);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    MediaData()
  }, []);


  return (
    <div className="Media mb-5">
      <section className="inner-banner-Media">
        <div className="container">
          <div className="inner-bannerimg"></div>
        </div>
      </section>
      <div className="line-null mb-5">
        <div className="line mt-5"></div>
        <div className="top-line">{t('ஊடகம்')}</div>
      </div>
      <div className="container mt-5">
        <div class="row">
        {mediaData.map((media) => (
                
        <div className="col-lg-4 px-4">

        <div class="item">
          <a href="#0" aria-labelledby="person1"></a>
          <img
            loading="lazy"
            src={media?.file}
            className="img-fluid m-auto d-block "
            alt="image"
          />
          <div class="item__overlay">
            <div className="d-flex ">
              <p className="date-of-day">
                28<span></span><br /><span>{t('மே')}</span> </p>
              <h3 id="person1" aria-hidden="true">{t(media?.tittle)}</h3>
            </div>

            <div class="item__body">
              <p>{t(`${media?.tittle}news`)}</p>
            </div>
          </div>
        </div>
        </div>
        ))}

        {/* <div className="col-lg-4 px-4">

        <div class="item">
          <a href="#0" aria-labelledby="person1"></a>
          <img
            loading="lazy"
            src={process.env.PUBLIC_URL + "/images/media/media22.jpg"}
            className="img-fluid m-auto d-block "
            alt="image"
          />
          <div class="item__overlay">
            <div className="d-flex ">
              <p className="date-of-day">28<span></span><br /><span>{t('மே')}</span> </p>
              <h3 id="person1" aria-hidden="true">{t('உலகப் பட்டினி தினமான மே 28ல் விஜய் சார்பில் அன்னதானம்')}</h3>
            </div>

            <div class="item__body">
              <p>{t('உலகப் பட்டினி தினமான மே 28ல் விஜய் சார்பில் அன்னதானம் செய்தி')}</p>
            </div>
          </div>
        </div>
        </div>


          <div className="col-lg-4 px-4">

            <div class="item">
              <a href="#0" aria-labelledby="person1"></a>
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/media/media19.jpg"}
                className="img-fluid m-auto d-block "
                alt="image"
              />
              <div class="item__overlay">
                <div className="d-flex ">
                  <p className="date-of-day"> <span>15</span><br /><span>{t('மே')}</span> </p>
                  <h3 id="person1" aria-hidden="true">{t('மாணவ, மாணவிகளை தேர்ந்தெடுக்கும் பணி தொடங்கியது')}</h3>
                </div>

                <div class="item__body">
                  <p>{t('மாணவ, மாணவிகளை தேர்ந்தெடுக்கும் பணி தொடங்கியது செய்தி')}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 px-4 mt-5">

            <div class="item">
              <a href="#0" aria-labelledby="person1"></a>
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/media/media18.png"}
                className="img-fluid m-auto d-block "
                alt="image"
              />
              <div class="item__overlay">
                <div className="d-flex ">
                  <p className="date-of-day"> <span>09</span><br /><span>{t('மே')}</span> </p>
                  <h3 id="person1" aria-hidden="true">{t('மதுரையில் மாநாடு')}</h3>
                </div>

                <div class="item__body">
                  <p>{t('மதுரையில் மாநாடு செய்தி')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 px-4 mt-5">

            <div class="item">
              <a href="#0" aria-labelledby="person1"></a>
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/media/media4.jpeg"}
                className="img-fluid m-auto d-block "
                alt="image"
              />
              <div class="item__overlay">
                <div className="d-flex ">
                  <p className="date-of-day"> <span>02</span><br /><span>{t('பிப்ரவரி')}</span> </p>
                  <h3 id="person1" aria-hidden="true">{t('கட்சி தொடக்கம்')}</h3>
                </div>

                <div class="item__body">
                  <p>{t('கட்சி தொடக்கம் செய்தி')}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 px-4 mt-5">

            <div class="item">
              <a href="#0" aria-labelledby="person1"></a>
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/media/media20.jpg"}
                className="img-fluid m-auto d-block "
                alt="image"
              />
              <div class="item__overlay">
                <div className="d-flex ">
                  <p className="date-of-day"> <span>12</span><br /><span>{t('நவம்பர்')}</span> </p>
                  <h3 id="person1" aria-hidden="true">{t('தளபதி விஜய் நூலகம்')}</h3>
                </div>

                <div class="item__body">
                  <p>{t('தளபதி விஜய் நூலகம் செய்தி')}</p>
                </div>
              </div>
            </div>
          </div>


          <div className="col-lg-4 px-4 mt-5">
            <div class="item">
              <a href="#0" aria-labelledby="person2"></a>
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/media/media14.jpg"}
                className="img-fluid m-auto d-block "
                alt="image"
              />
              <div class="item__overlay">
                <div className="d-flex ">
                  <p className="date-of-day"> <span>02</span><br /><span>{t('பிப்ரவரி')}</span> </p>
                  <h3 id="person1" aria-hidden="true">{t('கேரளா நிதியுதவி')}</h3>
                </div>

                <div class="item__body">
                  <p> {t('கேரளா நிதியுதவி செய்தி')}</p>
                </div>
              </div>
            </div>
          </div>


          <div className="col-lg-4 px-4 mt-5">

            <div class="item">
              <a href="#0" aria-labelledby="person3"></a>
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/media/media16.jpg"}
                className="img-fluid m-auto d-block "
                alt="image"
              />
              <div class="item__overlay">
                <div className="d-flex ">
                  <p className="date-of-day"> <span>22</span><br /><span>{t('ஏப்ரல்')}</span> </p>
                  <h3 id="person1" aria-hidden="true">{t('கரோனா நிவாரணம்')}</h3>
                </div>
                <div class="item__body">
                  <p>{t('கரோனா நிவாரணம் செய்தி')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 px-4 mt-5" >

            <div class="item">
              <a href="#0" aria-labelledby="person1"></a>
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/media/media1.jpg"}
                className="img-fluid m-auto d-block "
                alt="image"
              />
              <div class="item__overlay">
                <div className="d-flex ">
                  <p className="date-of-day"> <span>02</span><br /><span>{t('பிப்ரவரி')}</span> </p>
                  <h3 id="person1" aria-hidden="true">{t('கல்வி உதவித்தொகை')}</h3>
                </div>
                <div class="item__body">
                  <p>{t('கல்வி உதவித்தொகை செய்தி')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 px-4 mt-5">

            <div class="item">
              <a href="#0" aria-labelledby="person2"></a>
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/media/media13.jpg"}
                className="img-fluid m-auto d-block "
                alt="image"
              />
              <div class="item__overlay">
                <div className="d-flex ">
                  <p className="date-of-day"> <span>22</span><br /><span>{t('பிப்ரவரி')}</span> </p>
                  <h3 id="person1" aria-hidden="true">{t('மீனவர்கள் மீதான தாக்குதல்')}</h3>
                </div>
                <div class="item__body">
                  <p>{t('மீனவர்கள் மீதான தாக்குதல் செய்தி')}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 px-4 mt-5">

            <div class="item">
              <a href="#0" aria-labelledby="person3"></a>
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/media/media12.jpg"}
                className="img-fluid m-auto d-block "
                alt="image"
              />
              <div class="item__overlay">
                <div className="d-flex ">
                  <p className="date-of-day"> <span>05</span><br /><span>{t('டிசம்பர்')}</span> </p>
                  <h3 id="person1" aria-hidden="true">
                    {t('நிவாரண உதவிகள்')}</h3>
                </div>
                <div class="item__body">
                  <p>{t('நிவாரண உதவிகள் செய்தி')}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 px-4 mt-5">

            <div class="item">
              <a href="#0" aria-labelledby="person1"></a>
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/media/media7.png"}
                className="img-fluid m-auto d-block "
                alt="image"
              />
              <div class="item__overlay">
                <div className="d-flex ">
                  <p className="date-of-day"> <span>02</span><br /><span>{t('நவம்பர்')}</span> </p>
                  <h3 id="person1" aria-hidden="true">{t('கறவை மாடுகள் தானம்')}</h3>
                </div>
                <div class="item__body">
                  <p>{t('கறவை மாடுகள் தானம் செய்தி')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 px-4 mt-5">

            <div class="item">
              <a href="#0" aria-labelledby="person2"></a>
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/media/media10.png"}
                className="img-fluid m-auto d-block "
                alt="image"
              />
              <div class="item__overlay">
                <div className="d-flex ">
                  <p className="date-of-day"> <span>29</span><br /><span>{t('டிசம்பர்')}</span> </p>
                  <h3 id="person1" aria-hidden="true">{t('கடல்கொந்தளி')}</h3>
                </div>
                <div class="item__body">
                  <p>{t('கடல்கொந்தளி செய்தி')}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 px-4 mt-5">

            <div class="item">
              <a href="#0" aria-labelledby="person3"></a>
              <img
                loading="lazy"
                src={process.env.PUBLIC_URL + "/images/media/media9.jpg"}
                className="img-fluid m-auto d-block "
                alt="image"
              />
              <div class="item__overlay">
                <div className="d-flex ">
                  <p className="date-of-day"> <span>05</span><br /><span>{t('ஜனவரி')}</span> </p>
                  <h3 id="person1" aria-hidden="true">{t('இலங்கை நிவாரண நிதி')}</h3>
                </div>

                <div class="item__body">
                  <p>{t('இலங்கை நிவாரண நிதி செய்தி')}</p>
                </div>
              </div>
            </div>
          </div> */}

        </div>

      </div>

    </div>
  );
};

export default Media;
