import React, { useEffect } from "react";
import "./Pledge.css";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";

const Pledge = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pledge-page mb-5">
      <section className="inner-banner">
        <div className="container">
          <div className="inner-bannerimg"></div>
        </div>
      </section>
      <div className="line-null mb-5">
        <div className="line mt-5"></div>
        <div className="top-line">{t("உறுதிமொழி")} </div>
      </div>
      <section className="tvk-history my-5">
        <div className="container">
          <div className="row tvk-pledgelist ">
            <div className="col-lg-4 col-md-4">
            <figure>
              <img loading="lazy" src={process.env.PUBLIC_URL + '/images/pledge-images.png'} className="img-fluid" alt="icon" />
                <figcaption>தமிழக வெற்றிக் கழகம் </figcaption>
              </figure>
            </div>
            <div className="col-lg-8  col-md-8 tvk-pledges mt-3">
              <h2 className="mb-3">
                {t("தமிழக வெற்றிக் கழகத்தின்  உறுதிமொழிகள்")}{" "}
              </h2>
              <Carousel interval={2000}>
                <Carousel.Item>
                  <div className="col-lg-12">
                    <h5 class="category text-danger">{t("நாட்டுப்பற்று")}</h5>
                    <p class="card-description"> {t("உறுதிமொழி1")} </p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="col-lg-12">
                    <h5 class="category text-danger">{t("கடமைகள்")}</h5>
                    <p class="card-description">{t("உறுதிமொழி2")}</p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="col-lg-12">
                    <h5 class="category text-danger">{t("சமத்துவம்")}</h5>
                    <p class="card-description">{t("உறுதிமொழி3")}</p>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
          
        </div>
      </section>
    </div>
  );
};

export default Pledge;
