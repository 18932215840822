
const accessKey = process.env.REACT_APP_BSJDGSHDKBR

const helpmate = {
  activeKey: function (date) {
    var res = window.location.pathname
    var baseUrl = process.env.PUBLIC_URL
    baseUrl = baseUrl.split("/");
    res = res.split("/");
    res = res.length > 0 ? res[baseUrl.length] : "/";
    res = res ? "/" + res : "/";;
    const activeKey1 = res;
    return activeKey1
  },

  EmailRegex: /^(?!.*?\.\..*?@)(?!.*?@\..*?\..*?$)[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[A-Za-z0-9-]+\.[A-Za-z.]{2,}$/,
  ageRegex : /^(1[8-9]|[2-9][0-9]|1[01][0-9]|120)$/,
  bloodTypeRegex : /^[^0-9]+$/,
  postalCodeRegex : /^\d{6}(-\d{4})?$/,
  volRegex : /^[^\d]+$/,
  mobileNumberRegex : /^[789]\d{9}$/,
  emailRegex : /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  voterIdRegex : /^[A-Z]{3}[0-9]{7}$/,
  aadharRegex : /^\d{12}$/,

  isLogin : function () {
    
    let isChk = localStorage.getItem(accessKey) ? true : false;

    return isChk;
  }
}



export default helpmate


