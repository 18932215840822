
import { React, useEffect } from 'react';
import AOS from 'aos';
import Header from './Component/Common/Header/Header';
import Footer from './Component/Common/Footer/Footer';
import MainIndex from './MainIndex';
import Helpmate from './Helper/Common';
import Sidebarmenu from './Component/Sidebar/Sidebarmenu';
import '../src/App.css';

function App() {

  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);

  return (
    <div>


      <Header />


      <MainIndex />



      <Footer />

    </div>
  );
}

export default App;
