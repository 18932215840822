import React from 'react'
import { Link } from 'react-router-dom'
import './sidebar.css'
import { useTranslation } from 'react-i18next'
const Sidebarmenu = () => {

	const { t } = useTranslation()

	return (
		<div className='sidebarocntent'>
			<div className='sidebar-header p-2'>
				<Link to="/" className=" d-flex gap-2 align-items-center" >
					<img loading="lazy" src={process.env.PUBLIC_URL + '/images/logo.png'} className="img-fluid " alt="icon" />
					<p className='mb-0'>{t('தமிழக வெற்றிக் கழகம்')}</p>
				</Link>
			</div>
			
			<ul className='menu-items p-0'>
				<li><Link to="/userdashboard" className='link-active'><span className='mx-2'><svg fill="#a10101" width="20" height="20" id="Layer_1" data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
					<path className="cls-1" d="M2,2V14H14V2ZM13,13H3V3H13Z" />
					<rect className="cls-1" x="9" y="9" width="3" height="1" />
					<path className="cls-1" d="M12,4H4V8h8ZM11,7H5V5h6Z" />
					<path className="cls-1" d="M8,9H4v3H8ZM7,11H5V10H7Z" />
					<rect className="cls-1" x="9" y="11" width="3" height="1" />
				</svg></span>{t('முகப்புப்பெட்டி')}</Link></li>
				<li className='memcard'><Link to="/membercard"><span className='mx-2'><svg width="20" height="20" viewBox="0 0 24 24" >
					<g>
						<path fill="none" d="M0 0h24v24H0z" />
						<path stroke='#a10101' strokeWidth='1' d="M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM4 5v14h16V5H4zm2 2h6v6H6V7zm2 2v2h2V9H8zm-2 6h12v2H6v-2zm8-8h4v2h-4V7zm0 4h4v2h-4v-2z" />
					</g>
				</svg></span>{t('உறுப்பினர் அட்டை')}</Link></li>
				<li><Link to="/addpost"><span className='mx-2'><svg width="20" height="20" viewBox="0 -1 22 22" version="1.1" >
					<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g id="Dribbble-Light-Preview" transform="translate(-260.000000, -2199.000000)" fill="#a10101">
							<g id="icons" transform="translate(56.000000, 160.000000)">
								<path d="M226,2058 C226,2058.552 225.552,2059 225,2059 L221,2059 C220.448,2059 220,2058.552 220,2058 C220,2057.448 220.448,2057 221,2057 L225,2057 C225.552,2057 226,2057.448 226,2058 M214.623,2048.933 C214.037,2048.885 213.864,2048.893 213.368,2048.93 C212.017,2048.639 211,2047.437 211,2046 C211,2044.346 212.346,2043 214,2043 C215.654,2043 217,2044.346 217,2046 C217,2047.44 215.979,2048.645 214.623,2048.933 M206.012,2056.059 C206.011,2056.038 206,2056.021 206,2056 L206,2042 C206,2041.448 206.448,2041 207,2041 L213.936,2041 C211.205,2041.035 209,2043.257 209,2045.997 C209,2047.441 209.622,2048.734 210.602,2049.647 C208.107,2050.805 206.307,2053.204 206.012,2056.059 M214.064,2041 L221,2041 C221.552,2041 222,2041.448 222,2042 L222,2051.09 C222,2051.593 222.408,2052 222.91,2052 C223.668,2052 224,2051.594 224,2051.09 L224,2041 C224,2039.895 223.105,2039 222,2039 L206,2039 C204.895,2039 204,2039.895 204,2041 L204,2057 C204,2058.104 204.895,2059 206,2059 L216.09,2059 C216.593,2059 217,2058.593 217,2058.09 C217,2057.332 216.594,2057 216.09,2057 L207.975,2057 C207.974,2057 207.969,2056.934 207.969,2056.893 C207.969,2050.795 215.355,2049.989 217.21,2051.713 C218.221,2052.652 219.716,2051.151 218.596,2050.356 C218.214,2050.084 217.806,2049.845 217.375,2049.642 C218.368,2048.728 219,2047.443 219,2045.987 C219,2043.248 216.795,2041.035 214.064,2041" id="profile_image_minus_round-[#1329]">

								</path>
							</g>
						</g>
					</g>
				</svg></span>{t('இடுகையைச் சேர்க்கவும்')}</Link></li>
				{/* <li><Link to="/joinmember"><span className='mx-2'><svg fill="#a10101" width="20" height="20"
					viewBox="0 0 100 100">

					<g>
						<g>
							<path d="M57,44h-6h-6c-3.3,0-6,2.7-6,6v9c0,1.1,0.5,2.1,1.2,2.8c0.7,0.7,1.7,1.2,2.8,1.2v9c0,3.3,2.7,6,6,6h2h2
			c3.3,0,6-2.7,6-6v-9c1.1,0,2.1-0.4,2.8-1.2c0.7-0.7,1.2-1.7,1.2-2.8v-9C63,46.7,60.3,44,57,44z"/>
						</g>
						<g>
							<circle cx="51" cy="33" r="7" />
						</g>
						<g>
							<path d="M36.6,66.7c-0.2-0.2-0.5-0.4-0.7-0.6c-1.9-2-3-4.5-3-7.1v-9c0-3.2,1.3-6.2,3.4-8.3c0.6-0.6,0.1-1.7-0.7-1.7
			c-1.7,0-3.6,0-3.6,0h-6c-3.3,0-6,2.7-6,6v9c0,1.1,0.5,2.1,1.2,2.8c0.7,0.7,1.7,1.2,2.8,1.2v9c0,3.3,2.7,6,6,6h2h2
			c0.9,0,1.7-0.2,2.4-0.5c0.4-0.2,0.6-0.5,0.6-0.9c0-1.2,0-4,0-5.1C37,67.2,36.9,66.9,36.6,66.7z"/>
						</g>
						<g>
							<circle cx="32" cy="29" r="7" />
						</g>
						<g>
							<path d="M76,40h-6c0,0-1.9,0-3.6,0c-0.9,0-1.3,1-0.7,1.7c2.1,2.2,3.4,5.1,3.4,8.3v9c0,2.6-1,5.1-3,7.1
			c-0.2,0.2-0.4,0.4-0.7,0.6c-0.2,0.2-0.4,0.5-0.4,0.8c0,1.1,0,3.8,0,5.1c0,0.4,0.2,0.8,0.6,0.9c0.7,0.3,1.5,0.5,2.4,0.5h2h2
			c3.3,0,6-2.7,6-6v-9c1.1,0,2.1-0.4,2.8-1.2c0.7-0.7,1.2-1.7,1.2-2.8v-9C82,42.7,79.3,40,76,40z"/>
						</g>
						<g>
							<circle cx="70" cy="29" r="7" />
						</g>
					</g>
				</svg></span> உறுப்பினர் இணை</Link></li> */}
				
				<li className='memcard'><Link to="#"><span className='mx-2'><svg width="20" height="20" viewBox="0 0 24 24" fill="none" >
					<path d="M15 16.5V19C15 20.1046 14.1046 21 13 21H6C4.89543 21 4 20.1046 4 19V5C4 3.89543 4.89543 3 6 3H13C14.1046 3 15 3.89543 15 5V8.0625M11 12H21M21 12L18.5 9.5M21 12L18.5 14.5" stroke="#a10101" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				</svg></span>{t('வெளியேறு')}</Link></li>


			</ul>
		</div>
	)
}

export default Sidebarmenu