import React from "react";
import "./Organization.css";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Organization = () => {
  const { t } = useTranslation();

  return (
    <div className="Organization">
      <section className="inner-banner-organi">
        <div className="container">
          <div className="inner-bannerimg"></div>
        </div>
      </section>
      <div className="line-null mb-5">
        <div className="line mt-5"></div>
        <div className="top-line">{t("கழக அமைப்பு")}</div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <Link to="/Administrators">
              <article>
                <div className="article-wrapper">
                  <figure>
                    <img
                      loading="lazy"
                      src={process.env.PUBLIC_URL + "/images/tvk-2026.jpg"}
                      className="img-fluid m-auto d-block "
                      alt="icon"
                    />
                  </figure>
                  <div className="article-body">
                    <h2>{t("தலைமை")} </h2>
                  </div>
                </div>
              </article>
            </Link>
          </div>
          <div className="col-md-4 col-sm-6">
            <Link to="/Administrators">
            <article>
              <div className="article-wrapper">
                <figure>
                  <img
                    loading="lazy"
                    src={process.env.PUBLIC_URL + "/images/ussy-anand.jpg"}
                    className="img-fluid m-auto d-block "
                    alt="icon"
                  />
                </figure>
                <div className="article-body">
                  <h2>{t("செயலாளர்")} </h2>
                </div>
              </div>
            </article>
          </Link>
            
          </div>
          <div className="col-md-4 col-sm-6">
            <Link to="/Administrators">
            <article>
              <div className="article-wrapper">
                <figure>
                  <img
                    loading="lazy"
                    src={process.env.PUBLIC_URL + "/images/tvk-2026.jpg"}
                    className="img-fluid m-auto d-block "
                    alt="icon"
                  />
                </figure>
                <div className="article-body">
                  <h2>{t("பொருளாளர்")} </h2>
                </div>
              </div>
            </article>
          </Link>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organization;
