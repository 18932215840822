
// const dateConverter = {

//     convertDate : function(data) {                   
      
//           let value ;
//           if(data) {

//             const dateString = data
//             const date = new Date(dateString);
//             const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
//             value = formattedDate

//           }else{

//             value = false;
            
//           } 
  
//         return value;
//     },

//   };
//   export default dateConverter;

export const daymonthyearformat = (date) => {
  date = new Date(date);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  var dates = date.getDate();
  var months = date.getMonth() + 1;
  var years = date.getFullYear();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  dates = dates < 10 ? "0" + dates : dates;
  months = months < 10 ? "0" + months : months;
  var strTime = hours + ":" + minutes + ":" + seconds + " " + ampm;
  return dates + "/" + months + "/" + years + " " + strTime;
};

export const dateConverter = (date) =>{

}
