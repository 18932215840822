import React from 'react'
import './Administrators.css'

import { useTranslation } from 'react-i18next'

const Administrators = () => {

    const { t } = useTranslation()

    return (
        <div className='Administrators mb-5'>
            <section className="inner-banner-Administrators">
                <div className="container">
                    <div className="inner-bannerimg"></div>
                </div>
            </section>
            <div className="line-null mb-5">
                <div className="line mt-5"></div>
                <div className="top-line">{t('நிர்வாகிகள்')}</div>
            </div>
            <div class="container">
                <div class="row addtional ">
                    <div class="col-lg-4">
                        <div class="card radius-10 border-start border-0 border-3 border-info">
                            <div class="card-body">
                                <div class="row d-flex align-items-center">
                                    <div className='col-lg-8'>
                                        <p class="mb-0 text-secondary">{t('தலைவர்')}</p>
                                        <h4 class="my-1 text-info">{t('தலைமைபெயர்')}</h4>
                                        <p class="mb-0 font-13">{t('தமிழக வெற்றிக் கழகம்')}</p>
                                    </div>
                                    <div className='col-lg-4'>

                                        <div class="widgets-icons-2 rounded-circle bg-gradient-scooter  ">
                                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png '} className=" mb-3 member-banner img-fluid " alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card radius-10 border-start border-0 border-3 border-info">
                            <div class="card-body">
                                <div class="row d-flex align-items-center">
                                    <div className='col-lg-8'>
                                        <p class="mb-0 text-secondary">{t('பொது செயலாளர்')}</p>
                                        <h4 class="my-1 text-info">{t('பொது செயலாளர் பெயர்')}</h4>
                                        <p class="mb-0 font-13">{t('தமிழக வெற்றிக் கழகம்')}</p>
                                    </div>
                                    <div className='col-lg-4'>

                                        <div class="widgets-icons-2 rounded-circle bg-gradient-scooter  ">
                                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png '} className=" mb-3 member-banner img-fluid " alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card radius-10 border-start border-0 border-3 border-danger">
                            <div class="card-body">
                                <div class=" row d-flex align-items-center">
                                    <div className='col-lg-8'>
                                        <p class="mb-0 text-secondary">{t('மாநில இணைச் செயலாளர்')}</p>
                                        <h4 class="my-1 text-danger">{t('மாநில இணைச் செயலாளர் பெயர்')}</h4>
                                        <p class="mb-0 font-13">{t('மாநில இணைச் செயலாளர் முகவரி')}</p>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div class="widgets-icons-2 rounded-circle bg-gradient-bloody  ">
                                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png '} className=" mb-3 member-banner img-fluid " alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row addtional mt-3">
                    <div class="col-lg-4">
                        <div class="card radius-10 border-start border-0 border-3 border-success">
                            <div class="card-body">
                                <div class="row d-flex align-items-center">
                                    <div className='col-lg-8'>
                                        <p class="mb-0 text-secondary">{t('மாநிலச் செயலாளர்')}</p>
                                        <h4 class="my-1 text-success">{t('மாநிலச் செயலாளர் பெயர்')}</h4>
                                        <p class="mb-0 font-13">{t('மாநிலச் செயலாளர் முகவரி')}</p>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div class="widgets-icons-2 rounded-circle bg-gradient-ohhappiness  ">
                                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png '} className=" mb-3 member-banner img-fluid " alt="icon" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card radius-10 border-start border-0 border-3 border-info">
                            <div class="card-body">
                                <div class="row d-flex align-items-center">
                                    <div className='col-lg-8'>
                                        <p class="mb-0 text-secondary">{t('மாநிலத் துணைச் செயலாளர்')}</p>
                                        <h4 class="my-1 text-info">{t('மாநிலத் துணைச் செயலாளர் பெயர்')}</h4>
                                        <p class="mb-0 font-13">{t('மாநிலத் துணைச் செயலாளர் முகவரி')}</p>
                                    </div>
                                    <div className='col-lg-4'>

                                        <div class="widgets-icons-2 rounded-circle bg-gradient-scooter  ">
                                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png '} className=" mb-3 member-banner img-fluid " alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card radius-10 border-start border-0 border-3 border-danger">
                            <div class="card-body">
                                <div class=" row d-flex align-items-center">
                                    <div className='col-lg-8'>
                                        <p class="mb-0 text-secondary">{t('மாநிலத் துணைச் செயலாளர்')}</p>
                                        <h4 class="my-1 text-danger">{t('மாநிலத் துணைச் செயலாளர் பெயர்1')}</h4>
                                        <p class="mb-0 font-13">{t('மாநிலத் துணைச் செயலாளர் முகவரி1')}</p>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div class="widgets-icons-2 rounded-circle bg-gradient-bloody  ">
                                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png '} className=" mb-3 member-banner img-fluid " alt="icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row addtional mt-3">
                    <div class="col-lg-4">
                        <div class="card radius-10 border-start border-0 border-3 border-success">
                            <div class="card-body">
                                <div class="row d-flex align-items-center">
                                    <div className='col-lg-8'>
                                        <p class="mb-0 text-secondary">{t('மாநிலப் பொருளாளர்')}</p>
                                        <h4 class="my-1 text-success">{t('மாநிலப் பொருளாளர் பெயர்')}</h4>
                                        <p class="mb-0 font-13">{t('மாநிலப் பொருளாளர் முகவரி')}</p>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div class="widgets-icons-2 rounded-circle bg-gradient-ohhappiness  ">
                                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/profile.png '} className=" mb-3 member-banner img-fluid " alt="icon" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Administrators