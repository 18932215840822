import React, { useEffect } from 'react'
import './Achievements.css'

import { useTranslation } from 'react-i18next'


const Achievements = () => {

    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='Achievements mb-5'>
            <section className='Achievements-banner'> 
                <div className='container'>
                    <div className='inner-bannerimg'>

                    </div>
                </div>
            </section>
            <div className='line-null mb-5'>
                <div className="line mt-5"></div>
                <div className="top-line">
                    {t('சாதனைகள்')}</div>
            </div>
            <div className='container mt-5'>
                <div className='row'>


                    <div className='col-lg-10 m-auto'>
                        <div className='Achiev-section'>
                            <div className='row mb-5'>
                                <div className='col-lg-6'>
                                    <img loading="lazy" src={process.env.PUBLIC_URL + '/images/vmilib.jpeg'} alt="image" />
                                </div>
                                <div className='col-lg-6 m-auto'>
                                    <h3 className='element-left-align'>{t('சாதனைகள்தலைப்பு1')}</h3>
                                    <p>{t('சாதனைகள்விளக்கம்1')}</p>
                                </div>

                            </div>
                            <div className='boder-head mb-5'></div>
                            <div className='row mb-5 '>
                                <div className='col-lg-6 m-auto'>
                                    <h3 className='element-left-align'>{t('சாதனைகள்தலைப்பு2')}</h3>
                                    <p>{t('சாதனைகள்விளக்கம்2')}</p>
                                </div>
                                <div className='col-lg-6'>
                                    <img loading="lazy" src={process.env.PUBLIC_URL + '/images/Achievement-2.jpg'} alt="image" />
                                </div>
                            </div>
                            <div className='boder-head mb-5'></div>
                            <div className='row mb-5 '>
                                <div className='col-lg-6'>
                                    <img loading="lazy" src={process.env.PUBLIC_URL + '/images/Achievement-3.jpg'} alt="image" />
                                </div>
                                <div className='col-lg-6 m-auto'>
                                    <h3 className='element-left-align'>{t('சாதனைகள்தலைப்பு3')}</h3>
                                    <p>{t('சாதனைகள்விளக்கம்3')}</p>
                                </div>

                            </div>
                            <div className='boder-head mb-5'></div>
                            <div className='row mb-5 '>

                                <div className='col-lg-6 m-auto '>
                                    <h3 className='element-left-align'>{t('சாதனைகள்தலைப்பு4')} </h3>
                                    <p>{t('சாதனைகள்விளக்கம்4')}</p>
                                </div>
                                <div className='col-lg-6'>
                                    <img loading="lazy" src={process.env.PUBLIC_URL + '/images/Achievement-4.jpg'} alt="image" />
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <div className='col-lg-4 px-4'>
                        <div className='achiv-lode'>
                            <div className='card'>
                                <div className='row '>
                                    <div className='col-lg-4'>
                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/Achievement-1.jpg'} alt="image" />
                                    </div>
                                    <div className='col-lg-8'>
                                        <h3>{t('சாதனைகள்தலைப்பு1')}</h3>
                                    </div>

                                </div>
                            </div>
                            <div className='card mt-4'>
                                <div className='row '>
                                    <div className='col-lg-4'>
                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/Achievement-2.jpg'} alt="image" />
                                    </div>
                                    <div className='col-lg-8'>
                                        <h3>{t('சாதனைகள்தலைப்பு2')}</h3>
                                    </div>

                                </div>
                            </div>
                            <div className='card mt-4'>
                                <div className='row '>
                                    <div className='col-lg-4'>
                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/Achievement-3.jpg'} alt="image" />
                                    </div>
                                    <div className='col-lg-8'>
                                        <h3>{t('சாதனைகள்தலைப்பு3')}</h3>
                                    </div>

                                </div>
                            </div>
                            <div className='card mt-4'>
                                <div className='row '>
                                    <div className='col-lg-4'>
                                        <img loading="lazy" src={process.env.PUBLIC_URL + '/images/Achievement-4.jpg'} alt="image" />
                                    </div>
                                    <div className='col-lg-8'>
                                        <h3>{t('சாதனைகள்தலைப்பு4')}</h3>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Achievements