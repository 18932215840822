import React, { useEffect } from 'react'
import './Events.css'
const Events = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='Events'>
            <section className='inner-banner-Events'>
                <div className='container'>
                    <div className='inner-bannerimg'>

                    </div>
                </div>
            </section>
            <div className='line-null mb-5'>
                <div className="line mt-5"></div>
                <div className="top-line">
                நிகழ்வு</div>
            </div>
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-lg-4 mb-2'>
                        <div className='card'>
                            <div className='campaign-videos'>
                                <div className="video-container">
                                    <iframe src="https://www.youtube.com/embed/FEHMvNajg5M?si=u3Rtv5rO6PJh5PUr" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                </div>
                            </div>
                            <p>வெள்ளத்தால் பாதிக்கப்பட்டவர்களுக்கு நிவாரண உதவிகளை நேரில் வழங்குகிய விஜய்</p>
                        </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                        <div className='card'>
                            <div className='campaign-videos'>
                                <div className="video-container">
                                    <iframe src="https://www.youtube.com/embed/A-KO3w00G5w?si=lPTZwsIM1T-tfFlV" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                </div>
                            </div>
                            <p>வெள்ளத்தால் பாதிக்கப்பட்டவர்களுக்கு நிவாரண உதவிகளை நேரில் வழங்குகிய விஜய்</p>
                        </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                        <div className='card'>
                            <div className='campaign-videos'>
                                <div className="video-container">
                                    <iframe src="https://www.youtube.com/embed/wn38lMxP_L8?si=-AA8e672evYhRgv_" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                </div>
                            </div>
                            <p>விஜய் விருது வழங்கும் விழாவில் நடந்த சுவாரசிய காட்சிகள்!</p>
                        </div>
                    </div>
                </div>
                <div className='row mt-5 '>
                    <div className='col-lg-4 mb-2'>
                        <div className='card'>
                        <div className='campaign-videos'>
                            <div className="video-container">
                                <iframe src="https://www.youtube.com/embed/LdZTe16RYgE?si=NbJM0wDd35k_qQTe" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                        </div>
                        <p>விஜய் விருது வழங்கும் விழாவில் நடந்த சுவாரசிய காட்சிகள்!</p>
                        </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                        <div className='card'>
                        <div className='campaign-videos'>
                            <div className="video-container">
                                <iframe src="https://www.youtube.com/embed/521JWzD68Hw?si=jO8ursG7h4VjSD_C" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                        </div>
                        <p>விஜய் விருது வழங்கும் விழாவில் நடந்த சுவாரசிய காட்சிகள்!</p>
                        <p></p>
                        </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                        <div className='card'> 
                        <div className='campaign-videos'>
                            <div className="video-container">
                                <iframe src="https://www.youtube.com/embed/ckk07nQko7Y?si=3XRYlL9VLSyxq9fS" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                        </div>
                        <p>பிரம்மாண்ட Ambulance வாங்கி கொடுத்த Vijay'🙏 'அந்த மனசு தான் கடவுள்'</p>
                        </div>
                    </div>
                </div>
                <div className='row mt-5 mb-5'>
                    <div className='col-lg-4 mb-2'>
                        <div className='card'>
                        <div className='campaign-videos'>
                            <div className="video-container">
                                <iframe src="https://www.youtube.com/embed/_KeNAc18whk?si=b2oiN-nLqZC95n0_" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                        </div>
                        <p>விஜய் விருது வழங்கும் விழாவில் நடந்த சுவாரசிய காட்சிகள்!</p>
                        </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                        <div className='card'>
                        <div className='campaign-videos'>
                            <div className="video-container">
                                <iframe src="https://www.youtube.com/embed/q6kS5Vp2rHk?si=laPGDTGmLGWTegJ2" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                        </div>
                        <p>விஜய்-க்கு பரிசளித்த மாணவன்... பரிசை பார்த்ததும் கண்கலங்கிய விஜய்..</p>
                        </div>
                    </div>
                    <div className='col-lg-4 mb-2'>
                        <div className='card'>
                        <div className='campaign-videos'>
                            <div className="video-container">
                                <iframe src="https://www.youtube.com/embed/73CBakTNIk0?si=TU_J36-pL4ORCBrP" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                        </div>
                        <p>கார் முதல் மேடை வரை | நடிகர் விஜய்</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Events